import { Component } from "react";
import { track } from "../clients/analytics/mixpanel";
import UserGuidePopup from "../components/popups/UserGuidePopup";
import { trackAuthentication } from "../clients/analytics/cliquemarket";
import GrocerizeLoadingWheel from "../components/grocerize/GrocerizeLoadingWheel";

import PageTemplate from "./PageTemplate";
import { withAuth0 } from "@auth0/auth0-react";
import { connect } from "react-redux";
import { getUserFavourites } from "../redux/actions/favouritesActions";
import { getUserPriceAlerts } from "../redux/actions/priceAlertActions";
import { getRecipesFromDatabase } from "../redux/actions/recipeActions";
import {
  checkUserStatus,
  deleteUserFromAuth0,
} from "../redux/actions/userActions";
import { toggleUnavailableProducts } from "../redux/actions/itemActions";
import { hideModal, showModal } from "../redux/actions/modalActions";
import RecipeBanner from "../components/recipes/RecipeBanner";
import RecipesGrid from "../components/recipes/RecipesGrid";

interface Props {
  recipes: RecipesState;
  getRecipesFromDatabase: any;
  auth0: any;
  search: SearchState;
  checkUserStatus: any;
  deleteUserFromAuth0: any;
  user: UserState;
  toggleUnavailableProducts: (isUnavailableProductsHidden: boolean) => void;
  isUnavailableProductsHidden?: boolean;
  showModal: any;
}

interface State {
  isLoading: boolean;
  recipesToDisplay: RecipeData[];
}

class RecipesHome extends Component<Props, State> {
  state = {
    isLoading: false,
    recipesToDisplay: [],
  };
  componentDidMount = async () => {
    if (!process.env.REACT_APP_DEV_MODE) {
      track("page_load--browse_items");
    }

    if (!this.hasVisitedWebsite()) {
      const modalContent = () => <UserGuidePopup />;
      this.props.showModal({ component: modalContent });
    }
    this.props.getRecipesFromDatabase();
  };

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.user !== this.props.user) {
      if (this.props.user.id && !process.env.REACT_APP_DEV_MODE) {
        trackAuthentication(
          {
            id: this.props.user.id,
            email: this.props.user.email,
            prefereredStore: this.props.user.shopping_preference,
            source: this.props.user.auth0_id,
            postcode: this.props.user.postcode,
          },
          "login"
        );
      }
    }
  };

  hasVisitedWebsite = () => {
    return localStorage.getItem("has-visited-website") === null ? false : true;
  };

  reloadPage = () => {
    <div className="o-modal">
      <div className="o-modal__background "></div>
      <div className="o-modal__container o-modal u-w-all">
        <div className="o-modal__content u-flex u-justify-center u-align-center u-h-all u-w-all">
          <GrocerizeLoadingWheel />
        </div>
      </div>
    </div>;
  };

  render() {
    if (this.state.isLoading) {
      this.reloadPage();
    }

    return (
      <PageTemplate
        headerType="magenta"
        sidebarType="none"
        hideItemSearching={true}
      >
        <div className="u-w-1300--desktop u-mauto">
          <div className="u-ph--16 u-w-all">
            <RecipeBanner recipeTitle="Recipes" />
          </div>
          <RecipesGrid
            getRecipesToDisplay={this.props.getRecipesFromDatabase}
          />
        </div>
      </PageTemplate>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  categories: state.categories,
  search: state.search,
  isUnavailableProductsHidden: state.items.isUnavailableProductsHidden,
  recipes: state.recipes,
});

export default withAuth0(
  connect(mapStateToProps, {
    getUserFavourites,
    getUserPriceAlerts,
    checkUserStatus,
    deleteUserFromAuth0,
    toggleUnavailableProducts,
    showModal,
    hideModal,
    getRecipesFromDatabase,
  })(RecipesHome)
);
