import { Component } from "react";
import ItemCardLoading from "../items/ItemCard/ItemCardLoading";
import ItemCard from "../items/ItemCard/ItemCard";
import PaginationClicker from "../naviagtions/PaginationClicker";
import { connect } from "react-redux";
import { getRecipesFromDatabase } from "../../redux/actions/recipeActions";
import RecipeCard from "./RecipeCard";

interface State {
  isLoading: boolean;
  recipesToDisplay: RecipeData[];
}

interface Props {
  recipes: RecipesState;
  getRecipesToDisplay: any;
}

class RecipesGrid extends Component<Props, State> {
  state = {
    isLoading: true,
    recipesToDisplay: [],
  };

  componentDidMount = () => {
    // find products related to it
    this.getRecipes();
    this.setState({
      recipesToDisplay: this.props.recipes.recipesData,
      isLoading: true,
    });
  };

  componentDidUpdate = (prevProps: Props) => {
    // if redux items state updates, display them
    if (prevProps.recipes.recipesData !== this.props.recipes.recipesData) {
      this.setState({
        recipesToDisplay: this.props.recipes.recipesData,
        isLoading: false,
      });
    }
  };

  getRecipes = () => {
    this.props.getRecipesToDisplay();
  };

  getWhiteSpaceHeight = () => {
    const headerHeight =
      document.getElementById("header")?.getBoundingClientRect().bottom || 0;
    const whiteSpaceHeight = window.innerHeight - headerHeight;
    const newItemsHeight = window.innerHeight - whiteSpaceHeight;
    const maxMarginTop = 200;

    const dynamicHeight =
      newItemsHeight > maxMarginTop
        ? maxMarginTop + "px"
        : newItemsHeight + "px";

    return dynamicHeight;
  };

  render() {
    const recipesToDisplay = this.state.recipesToDisplay;
    const pageSpacerMarginTop = this.getWhiteSpaceHeight();
    console.log(recipesToDisplay);

    const weekday = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    return (
      <>
        <div className="u-w-all u-background--grey u-relative">
          {this.state.isLoading && (
            <div className="u-background--grey u-w-all u-h-all u-grid--items u-ph--16 u-pb--16 u-mb--16">
              <ItemCardLoading />
              <ItemCardLoading />
              <ItemCardLoading />
              <ItemCardLoading />
              <ItemCardLoading />
            </div>
          )}
          {recipesToDisplay && recipesToDisplay.length === 0 && (
            <div
              className="u-main-font--large u-color-magenta u-semi-bold u-w-all u-p--32 u-ph--48 u-pb--16 u-mb--16"
              style={{ height: window.innerHeight }}
            >
              Sorry, no recipes are currently available. Check back again soon!
              <br />
            </div>
          )}
          {recipesToDisplay && recipesToDisplay.length > 0 && (
            <>
              <div
                className="u-background--grey u-grid--items u-pt--4--mobile u-ph--16 u-pb--16 u-mb--16"
                style={{ justifyContent: "center" }} // we might want to adjust this later to use className
              >
                {recipesToDisplay.map((item, index) => {
                  return (
                    <div key={`recipebox-${index}`}>
                      <div className="u-text-align-center u-main-font--large u-color-magenta u-semi-bold u-w-all u-p--32 u-ph--48 u-pb--16 u-mb--16">
                        {weekday[new Date(item.recipeDate).getDay()]}
                      </div>
                      <RecipeCard
                        recipeData={item}
                        key={item["id"] + "-" + index}
                      />
                    </div>
                  );
                })}
              </div>
              <PaginationClicker spacerMarginTop={pageSpacerMarginTop} />
            </>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  recipes: state.recipes,
});

export default connect(mapStateToProps, {
  getRecipesFromDatabase,
})(RecipesGrid);
