import { Component } from "react";

import { Redirect, Route, Switch } from "react-router-dom";

import { withAuth0 } from "@auth0/auth0-react";

import "./styles/index.scss";

import CompleteSignup from "./pages/CompleteSignup";
import Grocerize from "./pages/GrocerizeHome";
import ItemsHome from "./pages/ItemsHome";
import MyHome from "./pages/MyHome";

import Modal from "./components/modals/Modal";
import { routes } from "./utils/routes";

import axios from "axios";
import moment from "moment";
import { connect } from "react-redux";
import ProtectedRoute from "./clients/auth/ProtectedRoute";
import { HttpClient } from "./clients/auth/addTokenToHTTPRequests";
import SignupPage from "./components/auth/SignupForm";
import VerifyEmail from "./components/auth/VerifyEmail";
import WelcomePage from "./components/auth/Welcome";
import MobileUserGuide from "./components/grocerize/GrocerizeUserGuideMobile";
import Banner from "./components/notification/Banner";
import SignUpPromptPopup from "./components/popups/SignUpPromptPopup";
import ContactUs from "./components/views/ContactUsView";
import FAQsView from "./components/views/FAQsView";
import LandingPage from "./pages/LandingPage";
import MaintenanceMode from "./pages/MaintenanceMode";
import Page404 from "./pages/Page404";
import PremiumPage from "./pages/PremiumPage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import PublicListsPage from "./pages/PublicListsPage";
import SignUpTermsOfUse from "./pages/SignUpTermsOfUse";
import TermsOfUse from "./pages/TermsOfUse";
import {
  findListAndAddToBasket,
  getCurrentBasket,
  loadBasket,
} from "./redux/actions/basketActions";
import { getCategoryTreeFromDB } from "./redux/actions/categoryAction";
import { getUserFavourites } from "./redux/actions/favouritesActions";
import { showModal } from "./redux/actions/modalActions";
import { getUserPriceAlerts } from "./redux/actions/priceAlertActions";
import { getUserData } from "./redux/actions/userActions";
import { updateItemInBasket } from "./redux/reducers/basketReducer";
import {
  loadFromLocalStorage,
  removeItemsOnStorage,
} from "./utils/localStorageFunctions";
import RecipesHome from "./pages/RecipesHome";
import Recipe from "./pages/Recipe";
import UnlockPremiumModal from "./components/popups/UnlockPremiumModal";

interface Props {
  getCategoryTreeFromDB: any;
  auth0: any;
  getCurrentBasket: any;
  getUserData: any;
  getUserFavourites: any;
  getUserPriceAlerts: any;
  showModal: any;
  basketItems: ItemData[];
  loadBasket: any;
  modal: ModalInputs;
  findListAndAddToBasket: any;
}

interface State {
  correctMaintenancePassword: boolean;
  startTime: any;
  basketItems?: ItemData[];
  notifications: Notification[];
}

class App extends Component<Props, State> {
  state = {
    correctMaintenancePassword: false,
    startTime: moment().local(),
    basketItems: this.props.basketItems,
    notifications: [],
  };

  constructor(props: Props) {
    super(props);
    const { getAccessTokenSilently } = this.props.auth0;
    HttpClient.setTokenGenerator(() =>
      getAccessTokenSilently({ useRefreshTokens: true })
    );
  }

  componentDidMount = async () => {
    const WEBFLOW_PAGE_ID = "640ab55b97b40f94bc5cddbc";
    const WEBFLOW_SITE_ID = "63e101e0ed6570ca9e242473";

    var doc = document.getElementsByTagName("html")[0];
    doc.setAttribute("data-wf-page", WEBFLOW_PAGE_ID);
    doc.setAttribute("data-wf-site", WEBFLOW_SITE_ID);

    if (this.props.auth0.isAuthenticated) {
      await this.getUserProfileAndAssets();
    }

    setInterval(this.checkTimeElapsed, 60000);

    // get the category tree data
    this.props.getCategoryTreeFromDB();

    const { data } = await axios.get(
      process.env.REACT_APP_BACKEND_URL + "/notifications"
    );

    this.setState({ notifications: data });
  };

  manageItemsOnLocalStorage = async () => {
    const itemsOnStorage = loadFromLocalStorage();

    if (itemsOnStorage && itemsOnStorage.basketItems.length > 0) {
      await this.pushItemsToDB(itemsOnStorage.basketItems);
    }

    removeItemsOnStorage();
  };

  checkTimeElapsed = () => {
    const { startTime } = this.state;
    const currentTime = moment().local();
    const elapsedSeconds = currentTime.diff(startTime, "seconds");

    if (
      !this.props.auth0.isAuthenticated &&
      elapsedSeconds >= 60 &&
      this.props.modal.status === "hidden"
    ) {
      const modalPriceUpdated = () => <UnlockPremiumModal />;
      this.props.showModal({ component: modalPriceUpdated });

      // You might also want to reset the start time for subsequent checks
      this.setState({ startTime: moment().local() });
    }
  };

  pushItemsToDB = async (
    items: {
      id: number;
      quantity: number;
      selected_vendor_id?: number;
    }[]
  ) => {
    items.map(
      async (item: {
        id: number;
        quantity: number;
        selected_vendor_id?: number;
      }) => {
        await updateItemInBasket({
          item_id: item.id,
          quantity: item.quantity,
          selected_vendor_id: item.selected_vendor_id,
        });
      }
    );
  };

  componentDidUpdate = async (prevProps: Props) => {
    // if user logins in get their details
    if (
      this.props.auth0.isAuthenticated !== prevProps.auth0.isAuthenticated &&
      this.props.auth0.isAuthenticated
    ) {
      await this.manageItemsOnLocalStorage();

      await this.getUserProfileAndAssets();
      await this.props.getCurrentBasket();
    }
  };

  getUserProfileAndAssets = async () => {
    await Promise.allSettled([
      this.props.getUserData(),
      this.props.getUserFavourites(),
      this.props.getUserPriceAlerts(),
    ]);
  };

  // For Mobile View Rendering
  render() {
    if (
      process.env.REACT_APP_MAINTENANCE_MODE_PASSWORD &&
      !this.state.correctMaintenancePassword
    ) {
      return (
        <MaintenanceMode
          onCorrectPassword={() =>
            this.setState({ correctMaintenancePassword: true })
          }
        />
      );
    }

    return (
      <div className="u-h--var--mobile u-w-all u-overflow--hidden u-h-all">
        <div id="banner">
          {this.state.notifications.map(function (notification: Notification) {
            return <Banner key={notification.id} notification={notification} />;
          })}
        </div>

        <Switch>
          <Route exact path={routes.landing} component={LandingPage} />
          <Route exact path={routes.default}>
            <Redirect to={routes.browse} />
          </Route>
          {/* Components */}
          <Route exact path={routes.signup} component={CompleteSignup} />
          <Route exact path={routes.verifyEmail} component={VerifyEmail} />
          <Route exact path={routes.signupForm} component={SignupPage} />
          <Route exact path={routes.welcome} component={WelcomePage} />
          <Route exact path={routes.browse} component={ItemsHome} />
          <Route
            exact
            path={routes.mobileUserGuide}
            component={MobileUserGuide}
          />

          <Route
            exact
            path={routes.browseWithCategories}
            component={ItemsHome}
          />
          <Route exact path={routes.termsOfUse} component={TermsOfUse} />
          <Route
            exact
            path={routes.SignUpTermsOfUse}
            component={SignUpTermsOfUse}
          />
          <Route exact path={routes.privacyPolicy} component={PrivacyPolicy} />
          <Route exact path={routes.publicLists} component={PublicListsPage} />
          <Route exact path={routes.recipes} component={RecipesHome} />
          <Route exact path={routes.recipe} component={Recipe} />
          <Route exact path={routes.contact} component={ContactUs} />
          <Route exact path={routes.notFound} component={Page404} />

          {/* Must be logged in to view */}
          <Route exact path={routes.grocerize} component={Grocerize} />
          <Route exact path={routes.faqs} component={FAQsView} />
          <Route exact path={routes.premium} component={PremiumPage} />
          <ProtectedRoute path={routes.my} component={MyHome} exact={true} />

          {/* send unknown routes to home */}
          <Redirect to={routes.notFound} />
        </Switch>
        <Modal />
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  basketItems: state.basket.basketItems,
  modal: state.modal,
});

export default withAuth0<any>(
  connect(mapStateToProps, {
    getCategoryTreeFromDB,
    getCurrentBasket,
    getUserData,
    getUserPriceAlerts,
    getUserFavourites,
    showModal,
    loadBasket,
    findListAndAddToBasket,
  })(App)
);
