import { Component } from "react";
import { track } from "../clients/analytics/mixpanel";
import UserGuidePopup from "../components/popups/UserGuidePopup";
import { trackAuthentication } from "../clients/analytics/cliquemarket";
import GrocerizeLoadingWheel from "../components/grocerize/GrocerizeLoadingWheel";
import PageTemplate from "./PageTemplate";
import { withAuth0 } from "@auth0/auth0-react";
import { connect } from "react-redux";
import { getUserFavourites } from "../redux/actions/favouritesActions";
import { getUserPriceAlerts } from "../redux/actions/priceAlertActions";
import {
  getRecipeItemsFromDatabase,
  getRecipesFromDatabase,
} from "../redux/actions/recipeActions";
import {
  checkUserStatus,
  deleteUserFromAuth0,
} from "../redux/actions/userActions";
import { toggleUnavailableProducts } from "../redux/actions/itemActions";
import { hideModal, showModal } from "../redux/actions/modalActions";
import ReactMarkdown from "react-markdown";
import { addItemToBasket } from "../redux/actions/basketActions";
import axios from "axios";
import { saveToLocalStorage } from "../utils/localStorageFunctions";

interface Props {
  categories: CategoryState;
  recipes: RecipesState;
  basket?: BasketState;
  addItemToBasket: any;
  getRecipesFromDatabase: any;
  getRecipeItemsFromDatabase: any;
  auth0: any;
  search: SearchState;
  checkUserStatus: any;
  deleteUserFromAuth0: any;
  user: UserState;
  toggleUnavailableProducts: (isUnavailableProductsHidden: boolean) => void;
  isUnavailableProductsHidden?: boolean;
  showModal: any;
}

interface State {
  isLoading: boolean;
  recipesToDisplay: RecipeData[];
  currentRecipeId: string;
  imageLoadHasFailed: boolean;
  currentRecipe: RecipeData;
  addToCartLoading: boolean;
}

class Recipe extends Component<Props, State> {
  state = {
    isLoading: false,
    recipesToDisplay: [],
    currentRecipeId: window.location.pathname
      .toLowerCase()
      .split("/recipe/")[1],
    imageLoadHasFailed: false,
    currentRecipe: null,
    addToCartLoading: false,
  };

  componentDidMount = async () => {
    if (!process.env.REACT_APP_DEV_MODE) {
      track("page_load--browse_items");
    }
    // on page load get params
    if (!this.hasVisitedWebsite()) {
      const modalContent = () => <UserGuidePopup />;
      this.props.showModal({ component: modalContent });
    }
    await this.props.getRecipesFromDatabase();
  };

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.user !== this.props.user) {
      if (this.props.user.id && !process.env.REACT_APP_DEV_MODE) {
        trackAuthentication(
          {
            id: this.props.user.id,
            email: this.props.user.email,
            prefereredStore: this.props.user.shopping_preference,
            source: this.props.user.auth0_id,
            postcode: this.props.user.postcode,
          },
          "login"
        );
      }
    }
    if (prevProps.recipes !== this.props.recipes) {
      const recipeId = this.state.currentRecipeId;
      const currentRecipe = this.props.recipes.recipesData.find(
        (recipe) => recipe.id === parseInt(recipeId)
      );
      if (currentRecipe) {
        this.setState({ currentRecipe });
        const idListQ = JSON.stringify(currentRecipe.ingredientsProductIds);
        let recipeItems: ItemData[] = [];
        if (
          currentRecipe.ingredientsProductIds &&
          currentRecipe.ingredientsProductIds.length > 0
        ) {
          this.props.getRecipeItemsFromDatabase(
            currentRecipe.ingredientsProductIds
          );
        }
      }
    }
  };

  currentRecipe = () => {};

  addToBasket = async () => {
    this.setState({ addToCartLoading: true });
    const idListQ = JSON.stringify(
      this.state.currentRecipe.ingredientsProductIds
    );
    let recipeItems: ItemData[] = [];
    if (
      this.state.currentRecipe.ingredientsProductIds &&
      this.state.currentRecipe.ingredientsProductIds.length > 0
    ) {
      const result = await axios
        .get(process.env.REACT_APP_BACKEND_URL + `/search/idlist?q=${idListQ}`)
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          console.log(err);
        });
      if (result) {
        recipeItems = result?.items;
      }
    }

    if (recipeItems && recipeItems.length > 0) {
      recipeItems.map(async (item) => {
        await this.props.addItemToBasket(item);
        saveToLocalStorage({
          ...this.props.basket,
        });
      });
    }

    this.setState({ addToCartLoading: false });

    window.location.href = "/browse";
  };

  hasVisitedWebsite = () => {
    return localStorage.getItem("has-visited-website") === null ? false : true;
  };

  reloadPage = () => {
    return (
      <div className="o-modal">
        <div className="o-modal__background "></div>
        <div className="o-modal__container o-modal u-w-all">
          <div className="o-modal__content u-flex u-justify-center u-align-center u-h-all u-w-all">
            <GrocerizeLoadingWheel />
          </div>
        </div>
      </div>
    );
  };

  render() {
    if (this.state.isLoading && this.props.recipes.recipesData) {
      this.reloadPage();
    }
    const currentRecipe = this.state.currentRecipe;
    return (
      <PageTemplate
        headerType="magenta"
        sidebarType="none"
        hideItemSearching={true}
      >
        {currentRecipe && (
          <div className="u-w-1300--desktop u-mauto">
            <div className="u-ph--16 u-w-all u-flex--desktop u-align-center u-justify-between">
              <div className="u-relative u-w-all">
                <div className="u-justify-center u-align-center u-background--white u-border--darkgrey u-br--medium u-p--12 u-mt--24">
                  <div className="u-flex u-w-all">
                    <div className="u-w-half" style={{ maxWidth: "400px" }}>
                      {currentRecipe?.id ? (
                        <img
                          id="item-image"
                          style={{ width: "100%" }}
                          src={currentRecipe.imagePath}
                          alt={currentRecipe.title}
                          onError={() => {
                            this.setState({ imageLoadHasFailed: true });
                          }}
                        />
                      ) : (
                        <div
                          className="u-background--grey u-w-all u-main-font--vsmall u-semi-bold u-text-center"
                          style={{ width: "100%" }}
                        >
                          No image found.
                        </div>
                      )}
                    </div>
                    <div className="u-w-fit u-ml--16 u-mt--16 u-h-fit">
                      <div className="u-bold u-main-font--large">
                        {currentRecipe.title}
                      </div>
                      <div className="u-main-font--small">
                        <ReactMarkdown>{currentRecipe.summary}</ReactMarkdown>
                      </div>
                      <div className="u-pt--16">
                        <div className="u-main-font--vsmall u-bold u-pt--16">
                          TIPS
                        </div>
                        <div className="u-main-font--small u-pt--6">
                          <ReactMarkdown>
                            {currentRecipe.instructionsText}
                          </ReactMarkdown>
                        </div>
                        <div className="u-main-font--vsmall u-bold u-pt--16">
                          PREP TIME
                        </div>
                        <div className="u-main-font--vsmall u-pt--6">
                          {currentRecipe.prepTimeMinutes} minutes
                        </div>
                        <div className="u-main-font--vsmall u-bold u-pt--16">
                          COOK TIME
                        </div>
                        <div className="u-main-font--vsmall u-pt--6">
                          {currentRecipe.cookTimeMinutes} minutes
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="u-flex--desktop">
                  <div className="u-w-half--desktop u-m--16 u-p--12">
                    <div className="u-bold u-main-font--charcoal u-main-font--large">
                      Ingredients
                    </div>
                    <div>
                      <ReactMarkdown>
                        {currentRecipe.ingredientsText}
                      </ReactMarkdown>
                    </div>
                    <div>
                      <div className="u-w-all u-flex u-mt--24 u-ph--16--desktop u-min-height--48 u-max-height--48">
                        <button
                          className={
                            "u-br--small u-w-all u-background--magenta" +
                            (this.state.addToCartLoading ? " u-opacity--5" : "")
                          }
                          onClick={() => this.addToBasket()}
                        >
                          <div className="u-color-white u-p--12 u-main-font--small u-bold u-text-unselectable ">
                            Add all to Cart
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="u-w-half--desktop u-m--16 u-p--12">
                    <div className="u-bold u-main-font--charcoal u-main-font--large">
                      Steps
                    </div>
                    <div>
                      <ReactMarkdown>{currentRecipe.steps}</ReactMarkdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* Not sure why we are displaying items here instead of the recipe? */}
      </PageTemplate>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  basket: state.basket,
  recipes: state.recipes,
});

export default withAuth0(
  connect(mapStateToProps, {
    getUserFavourites,
    getUserPriceAlerts,
    addItemToBasket,
    checkUserStatus,
    deleteUserFromAuth0,
    toggleUnavailableProducts,
    showModal,
    hideModal,
    getRecipesFromDatabase,
    getRecipeItemsFromDatabase,
  })(Recipe)
);
