import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

interface Props {
  recipeData: RecipeData;
}

interface State {
  imageLoadHasFailed: boolean;
}

class RecipeCardMobile extends Component<Props, State> {
  state = {
    imageLoadHasFailed: false,
  };

  goToRecipe = () => {
    //this.props.history.push(`/recipe/${this.props.recipeData.id}`);
  };

  render() {
    const recipeData: RecipeData = this.props.recipeData;
    if (recipeData === undefined) {
      return null;
    }
    return (
      <div>
        <div
          id={this.props.recipeData.id.toString()}
          className={
            "card u-background--white u-br--small u-relative u-border--darkgrey"
          }
          style={{}}
        >
          <div className=" u-ph--24 u-pv--8 ">
            <div className="u-m0auto u-w-all u-text-unselectable u-text-center">
              {recipeData.id ? (
                <img
                  id="item-image"
                  style={{ height: "88px", width: "88px" }}
                  src={recipeData.imagePath}
                  alt={recipeData.title}
                  onError={() => {
                    this.setState({ imageLoadHasFailed: true });
                  }}
                />
              ) : (
                <div
                  className="u-background--grey u-w-all  u-main-font--vsmall u-semi-bold u-text-center"
                  style={{ height: "140px", width: "140px" }}
                >
                  No image found.
                </div>
              )}
              <div className="u-ph--12" style={{ height: "160px" }}>
                <div
                  className="u-color-charcoal u-main-font--small u-text-unselectable  u-pt--8 u-tooltip-wrap "
                  style={{
                    height: "64px",
                    lineHeight: "18px",
                    fontSize: "14px",
                  }}
                >
                  <div className="u-truncate--3l u-bold u-main-font--medium u-text-align-center">
                    {recipeData.title}
                  </div>
                  <div className="u-flex u-pt--16">
                    <div className="u-w-half">
                      <div className="u-main-font--vsmall u-text-align-center u-bold">
                        PREP TIME
                      </div>
                      <div className="u-main-font--vsmall u-text-align-center">
                        {recipeData.prepTimeMinutes} minutes
                      </div>
                    </div>
                    <div className="u-w-half">
                      <div className="u-main-font--vsmall u-text-align-center u-bold">
                        COOK TIME
                      </div>
                      <div className="u-main-font--vsmall u-text-align-center">
                        {recipeData.cookTimeMinutes} minutes
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="u-flex u-text-unselectable "
                  style={{ marginTop: "30px", marginBottom: "8px" }}
                ></div>
              </div>

              <div className="u-w-all u-flex u-justify-center u-ph--16--desktop u-min-height--48 u-max-height--48">
                <Link to={`/recipe/${this.props.recipeData.id}`}>
                  <button className="u-br--small u-background--magenta u-w-all">
                    <div className="u-color-white u-p--12 u-main-font--small u-bold u-text-unselectable ">
                      View recipe
                    </div>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  recipes: state.recipes,
});

export default connect(mapStateToProps, null)(RecipeCardMobile);
